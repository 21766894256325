export type PageRoute =
	| 'kontakt'
	| 'notarkanzlei'
	| 'anwaltskanzlei'
	| 'anwaelte';

export type PageTitle =
	| 'Notarkanzlei'
	| 'Anwaltskanzlei'
	| 'Kontakt'
	| 'Anwälte';

export type SubPage = {
	title: string;
	content: JSX.Element;
};

export type SubPages = SubPage[];

export type SubPagesWithPictures = (SubPage & {
	pictures: string[];
	subtitle: JSX.Element;
	additionalInformation?: string;
})[];

export type Route = { route: PageRoute; title: PageTitle };

export const routes: Route[] = [
	{ route: 'notarkanzlei', title: 'Notarkanzlei' },
	{ route: 'anwaltskanzlei', title: 'Anwaltskanzlei' },
	{ route: 'anwaelte', title: 'Anwälte' },
	{ route: 'kontakt', title: 'Kontakt' },
];
