import { Box } from '@mui/material';

export default function Logo(props: {
	size: string;
	firstColor: string;
	secondColor: string;
	withBorder?: boolean;
}) {
	const withBorder = props.withBorder ?? false;
	return (
		<Box
			sx={{
				my: 0,
				width: props.size,
				height: props.size,
				position: 'relative',
			}}
		>
			<Box
				sx={{
					my: 0,
					width: '100%',
					height: '100%',
					position: 'absolute',
					clipPath: 'polygon(0% 10%,90% 100%, 90% 10%)',
					backgroundColor: props.secondColor,
				}}
			></Box>
			{withBorder ? (
				<>
					<Box
						sx={{
							my: 0,
							width: '100%',
							height: '100%',
							position: 'absolute',
							clipPath: 'polygon(0% 0%, 0% 100%, 103% 100%, 3% 0%)',
							backgroundColor: 'black',
						}}
					></Box>
					<Box
						sx={{
							my: 0,
							width: '100%',
							height: '100%',
							position: 'absolute',
							clipPath: 'polygon(3% 3%, 3% 97%, 97% 97%)',
							backgroundColor: props.firstColor,
						}}
					></Box>
				</>
			) : (
				<Box
					sx={{
						my: 0,
						width: '100%',
						height: '100%',
						position: 'absolute',
						clipPath: 'polygon(0% 0%, 0% 100%, 100% 100%)',
						backgroundColor: props.firstColor,
					}}
				></Box>
			)}
		</Box>
	);
}
