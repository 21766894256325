import {
	Routes,
	Route,
	Navigate,
	useSearchParams,
	useLocation,
} from 'react-router-dom';
import Anwaelte from '../pages/anwaelte';
import Anwaltskanzlei from '../pages/anwaltskanzlei';
import Impressum from '../pages/impressum';
import Kontakt from '../pages/kontakt';
import NotFoundPage from '../pages/not-found';
import Notarkanzlei from '../pages/notarkanzlei';
import React from 'react';

export default function MyRoutes() {
	const [searchParams] = useSearchParams();

	const location = useLocation();
	const openParam = searchParams.get('open');
	const idParam = searchParams.get('id');

	console.log(idParam);

	// redirect old routes
	if (location.pathname === '/index.php') {
		switch (openParam) {
			case 'kontakt':
				return (
					<Routes>
						<Route path="*" element={<Navigate to="/kontakt" />}></Route>
					</Routes>
				);
			case 'anwaelte':
				return (
					<Routes>
						<Route path="*" element={<Navigate to="/anwaelte" />}></Route>
					</Routes>
				);
			case 'ueberuns':
				if (idParam === '15') {
					return (
						<Routes>
							<Route path="*" element={<Navigate to="/kontakt" />}></Route>
						</Routes>
					);
				} else {
					return (
						<Routes>
							<Route path="*" element={<Navigate to="/notarkanzlei" />}></Route>
						</Routes>
					);
				}
			case 'rechtsgebiete':
				return (
					<Routes>
						<Route path="*" element={<Navigate to="/anwaltskanzlei" />}></Route>
					</Routes>
				);
			case 'impressum':
				return (
					<Routes>
						<Route path="*" element={<Navigate to="/impressum" />}></Route>
					</Routes>
				);
			default:
				break;
		}
	}

	return (
		<Routes>
			<Route path="/notarkanzlei" element={<Notarkanzlei></Notarkanzlei>} />
			<Route
				path="/anwaltskanzlei"
				element={<Anwaltskanzlei></Anwaltskanzlei>}
			/>
			<Route path="/anwaelte" element={<Anwaelte></Anwaelte>} />
			<Route path="/kontakt" element={<Kontakt></Kontakt>} />
			<Route path="/impressum" element={<Impressum></Impressum>} />
			<Route
				path="/rechtsgebiete"
				element={<Navigate to="/anwaltskanzlei" />}
			/>
			<Route path="/" element={<Navigate to="/notarkanzlei" />} />
			<Route path="/index.html" element={<Navigate to="/notarkanzlei" />} />

			<Route path="/404" element={<NotFoundPage></NotFoundPage>} />
			<Route path="*" element={<NotFoundPage></NotFoundPage>} />
		</Routes>
	);
}
