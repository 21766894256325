import MenuIcon from '@mui/icons-material/Menu';
import {
	Divider,
	Drawer,
	Link,
	List,
	ListItem,
	ListItemButton,
	ListItemText,
	useTheme,
} from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { routes } from '../content';
import Logo from './helper/logo';

const drawerWidth = 240;

export default function NavBar() {
	const theme = useTheme();

	const [mobileOpen, setMobileOpen] = React.useState(false);

	const handleDrawerToggle = () => {
		setMobileOpen((prevState) => !prevState);
	};

	const title = () => (
		<Link
			component={NavLink}
			to={`/`}
			sx={{
				display: 'flex',
				textDecoration: 'none',
				color: 'black',
				margin: { sx: 'auto', md: '0' },
			}}
		>
			<Box sx={{ mr: 1, mt: 0, pt: 0 }}>
				<Logo size="35px" firstColor="white" secondColor="black" withBorder />
			</Box>
			<Typography
				variant="h6"
				noWrap
				sx={{
					mr: { xs: 0, md: 2 },
					letterSpacing: '.1rem',
					color: 'inherit',
					textDecoration: 'none',
					lineHeight: 2,
					fontSize: { xs: '18px', sm: '20px', lg: '22px' },
				}}
			>
				Grauf & Dr. Schneider
			</Typography>
		</Link>
	);

	const drawer = (
		<Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
			<Typography variant="h6" sx={{ my: 2 }}>
				Grauf & Dr. Schneider
			</Typography>
			<Divider />
			<List>
				{routes.map((page) => (
					<ListItem key={page.route} disablePadding>
						<ListItemButton
							sx={{
								textAlign: 'center',
								'&.active': {
									color: 'white',
									backgroundColor: theme.palette.primary.dark,
								},
							}}
							component={NavLink}
							to={`/${page.route}`}
						>
							<ListItemText primary={page.title} />
						</ListItemButton>
					</ListItem>
				))}
			</List>
		</Box>
	);

	return (
		<AppBar position="fixed" sx={{ zIndex: 2 }}>
			<Container maxWidth="xl" sx={{ px: { md: '0 !important' } }}>
				<Toolbar
					disableGutters
					sx={{ display: 'flex', justifyContent: 'space-between' }}
				>
					<Container
						sx={{ display: { xs: 'none', md: 'flex' }, maxWidth: '50%' }}
					>
						{title()}
					</Container>
					<Box sx={{ display: { xs: 'flex', md: 'none' } }}>
						<IconButton
							size="large"
							aria-label="account of current user"
							aria-controls="menu-appbar"
							aria-haspopup="true"
							onClick={handleDrawerToggle}
							color="inherit"
						>
							<MenuIcon />
						</IconButton>
					</Box>
					<Container sx={{ display: { xs: 'flex', md: 'none' } }}>
						{title()}
					</Container>
					<Box sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' } }}>
						{routes.map((route) => (
							<Button
								key={route.route}
								component={NavLink}
								to={`/${route.route}`}
								sx={{
									my: 0,
									px: 2,
									py: 2.5,
									borderRadius: 0,
									textTransform: 'none',
									color: 'inherit',
									'&.active': {
										color: 'white',
										backgroundColor: theme.palette.primary.dark,
									},
									'&:hover': {
										color: 'black',
										opacity: '90%',
										backgroundColor: '#c9cfdb',
									},
								}}
							>
								{route.title}
							</Button>
						))}
						<Logo
							size="65px"
							firstColor={theme.palette.primary.dark}
							secondColor="#516786"
						></Logo>
					</Box>
				</Toolbar>
			</Container>
			<Box component="nav">
				<Drawer
					variant="temporary"
					open={mobileOpen}
					onClose={handleDrawerToggle}
					ModalProps={{
						keepMounted: true, // Better open performance on mobile.
					}}
					sx={{
						display: { xs: 'block', md: 'none' },
						'& .MuiDrawer-paper': {
							boxSizing: 'border-box',
							width: drawerWidth,
						},
					}}
				>
					{drawer}
				</Drawer>
			</Box>
		</AppBar>
	);
}
