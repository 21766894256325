import {
	Box,
	Card,
	CardContent,
	CardMedia,
	Container,
	Divider,
	Typography,
	useTheme,
} from '@mui/material';
import headerPicture from '../assets/pictures/header_home.jpg';
import wappenPicture from '../assets/pictures/notarkanzlei_wappen.jpg';
import { SubPages } from '../content';

export const pages: SubPages = [
	{
		title: 'Notarkanzlei',
		content: (
			<>
				In unserer Notarkanzlei betreut Herr Notar Stefan Grauf, gleichzeitig
				Rechtsanwalt und Fachanwalt für Familienrecht, die notarielle Gestaltung
				von Rechtsgeschäften und die Vornahme von Beglaubigungen und
				Beurkundungen.
				<br />
				<br />
				Insbesondere bei der Gestaltung von Ehe- und Erbverträgen sowie
				Scheidungsvereinbarungen ermöglicht die langjährige Erfahrung und durch
				den Fachanwaltstitel für Familienrecht nachgewiesene Spezialisierung
				eine fundierte und umfassende Betreuung, bei der gerade auch die
				langfristigen und rechtsübergreifenden Folgen entsprechender
				Vereinbarungen berücksichtigt werden.
				<br />
				<br />
				Darüber hinaus beraten wir Sie im Zusammenhang mit dem Abschluss von
				Immobilienkaufverträgen und allen Vorgängen des
				Kapitalgesellschaftsrechtes.
				<br />
				<br />
				Zusammenfassend können Sie in unserer Notarkanzlei insbesondere
				Hilfestellungen <i>sowie anschließende Beurkundungen</i> in
				nachfolgenden Bereichen anfordern:
				<ul>
					<li>Eheverträge und Scheidungsvereinbarungen</li>
					<li>Erbverträge und Testamente</li>
					<li>Pflichtteilsverzichtsvereinbarungen</li>
					<li>Verträge der vorweggenommenen Erbfolge</li>
					<li>Immobilienkaufverträge</li>
					<li>Grundpfandrechtsbestellungen</li>
					<li>
						Gründung von Wohnungs- und Teileigentum durch Teilungserklärung
					</li>
					<li>Gesellschaftsgründungen</li>
					<li>Gesellschaftsanteilskaufverträge</li>
				</ul>
			</>
		),
	},
	{
		title: 'Gebühren',
		content: (
			<>
				Die Gebühren und Auslagen, die ein Notar in Rechnung stellen darf, sind
				durch das Gerichts- und Notarkostengesetz geregelt und nicht dispositiv.
				Die Höhe der Gebühren richtet sich nach dem Geschäftswert, also nach dem
				Wert des Gegenstands, auf den sich die notarielle Tätigkeit richtet.
			</>
		),
	},
];

export default function Notarkanzlei() {
	const theme = useTheme();

	return (
		<>
			<Box
				sx={{
					width: '100%',
					maxHeight: '40vh',
					objectFit: 'cover',
					objectPosition: '50% 30%',
				}}
				component="img"
				position={'relative'}
				src={headerPicture}
				alt="Notarkanzlei-Titelbild"
			></Box>

			<Box
				sx={{
					display: 'flex',
					mt: '-2rem',
					width: '100%',
					py: '1rem',
					pb: '2rem',
					backgroundColor: '#F4F6F9',
					justifyContent: 'center',
				}}
			>
				<Container maxWidth="lg" sx={{ pt: 4 }}>
					<Card sx={{ height: '100%', display: { xs: 'inherit', md: 'flex' } }}>
						<Box
							sx={{
								display: 'flex',
								flexDirection: 'column',
								maxWidth: { md: '30%' },
								justifyContent: 'center',
								mr: { md: 2 },
							}}
						>
							<CardMedia sx={{ minHeight: '100%', margin: 'auto' }}>
								<Box
									sx={{
										minHeight: '100%',
										maxHeight: { xs: '20vh', md: 'inherit' },

										maxWidth: '100%',
										objectFit: { xs: 'contain', lg: 'cover' },
									}}
									component="img"
									position={'relative'}
									src={wappenPicture}
									alt="Notarwappen"
									loading="lazy"
								></Box>
							</CardMedia>
						</Box>
						<CardContent>
							<Typography variant="h4">{pages[0].title}</Typography>
							<Divider
								sx={{
									width: '4rem',
									my: '1rem',
									background: theme.palette.primary.light,
								}}
							></Divider>
							<Box sx={{ p: 1 }}>{pages[0].content}</Box>
						</CardContent>
					</Card>
				</Container>
			</Box>

			<Container maxWidth="md" sx={{ py: 4 }}>
				{pages.slice(1, 2).map((subPage) => (
					<Card key={subPage.title}>
						<CardContent>
							<Typography variant="h4">{subPage.title}</Typography>

							<Divider
								sx={{
									width: '4rem',
									my: '1rem',
									background: theme.palette.primary.dark,
								}}
							></Divider>

							<Box sx={{ p: 1 }}>{subPage.content}</Box>
						</CardContent>
					</Card>
				))}
			</Container>
		</>
	);
}
