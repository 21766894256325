import { Divider, Typography, useTheme } from '@mui/material';
import { Container } from '@mui/system';
import React from 'react';
import { Helmet } from 'react-helmet-async';

export default function NotFoundPage() {
	const theme = useTheme();

	return (
		<>
			<Helmet>
				<meta name="robots" content="noindex" />
			</Helmet>
			<Container maxWidth="md" sx={{ paddingTop: 5 }}>
				<Typography variant="h4">Seite nicht gefunden</Typography>
				<Divider
					sx={{
						width: '4rem',
						my: '1rem',
						background: theme.palette.primary.dark,
					}}
				></Divider>
				<Typography>
					Fehler 404 - Seite nicht gefunden <br></br>
					Die angeforderte Seite existiert nicht oder steht temporär nicht zur
					Verfügung. Bitte versuchen Sie es später noch einmal oder rufen Sie
					die Startseite der gewünschten Domain auf.
				</Typography>
			</Container>
		</>
	);
}
