import {
	Box,
	Card,
	CardContent,
	CardMedia,
	Container,
	Divider,
	Grid,
	Typography,
	useTheme,
} from '@mui/material';
import headerPicture from '../assets/pictures/header_notarkanzlei.jpg';
import graufPicture from '../assets/pictures/grauf.jpg';
import grauf3Picture from '../assets/pictures/grauf3.jpg';
import schneiderPicture from '../assets/pictures/schneider.jpg';
import schneider3Picture from '../assets/pictures/schneider3.jpg';
import { SubPagesWithPictures } from '../content';
import Carousel from 'react-material-ui-carousel';

export const anwaelte: SubPagesWithPictures = [
	{
		title: 'Stefan Grauf',
		subtitle: (
			<>
				Rechtsanwalt, Notar
				<br />
				Fachanwalt für Familienrecht
			</>
		),
		content: (
			<>
				<ul style={{ margin: '0' }}>
					<br />
					<li>
						geboren 1964 in Sindelfingen
						<br />
					</li>
					<li>
						Studium der Rechtswissenschaft an der Universität Tübingen, dort
						auch wissenschaftlicher Mitarbeiter
						<br />
					</li>
					<li>
						zwei Prädikatsexamina
						<br />
					</li>
					<li>
						Mitglied im Deutschen Anwaltsverein und in der Arbeitsgemeinschaft
						Familien- und Erbrecht <br />
					</li>
					<li>
						langjähriger Dozent für Lehrveranstaltungen am Landgericht Tübingen
						und für den Interessenverband Unterhalt- und Familienrecht
						<br />
					</li>
				</ul>
			</>
		),
		pictures: [graufPicture, grauf3Picture],
	},
	{
		title: 'Dr. Markus Schneider',
		subtitle: (
			<>
				Rechtsanwalt
				<br />
				Fachanwalt für Familienrecht
			</>
		),
		content: (
			<>
				<ul style={{ margin: '0' }}>
					<br />
					<li>
						geboren 1974 in Stuttgart
						<br />
					</li>
					<li>
						Studium der Rechtswissenschaft an der Universität Tübingen <br />
					</li>
					<li>
						Prädikatsexamen
						<br />
					</li>
					<li>
						langjähriger Mitarbeiter am Lehrstuhl Prof. Dr. Dr. h.c. Jan
						Schröder, Universität Tübingen
						<br />
					</li>
					<li>
						Mitglied im Deutschen Anwaltsverein und in der Arbeitsgemeinschaft
						Familien- und Erbrecht
						<br />
					</li>
					<li>
						Promotion zum Dr. iur. an der Universität Tübingen im Polizeirecht
						<br />
					</li>
				</ul>
			</>
		),
		pictures: [schneiderPicture, schneider3Picture],
	},
];

export default function Anwaelte() {
	const theme = useTheme();

	return (
		<>
			<Box
				sx={{
					width: '100%',
					maxHeight: '40vh',
					objectFit: 'cover',
					objectPosition: '50% 30%',
				}}
				component="img"
				position={'relative'}
				src={headerPicture}
				alt="Anwaelte-Titelbild"
			></Box>

			<Container maxWidth="lg" sx={{ my: 2, mt: 2 }}>
				<Grid container spacing={2}>
					{anwaelte.map((page) => (
						<Grid item xs={12} md={6} key={page.title}>
							<Card sx={{ height: '100%', width: '100%' }}>
								<CardMedia
									children={[
										<Carousel
											fullHeightHover={false}
											indicatorContainerProps={{
												style: {
													position: 'relative',
												},
											}}
											animation="slide"
											duration={500}
											interval={10000}
											key={'carousel'}
										>
											{page.pictures.map((picture) => (
												<Box
													key={picture}
													sx={{
														objectFit: 'none',
														objectPosition: '50% 50%',
														width: '100%',
														height: '250px',
														margin: 'auto',
													}}
													component="img"
													position={'relative'}
													src={picture}
												></Box>
											))}
										</Carousel>,
									]}
								/>
								<CardContent>
									<Typography variant="h4" gutterBottom sx={{ mb: 1 }}>
										{page.title}
									</Typography>
									<Typography
										component={'span'}
										variant="h4"
										sx={{
											lineHeight: 1,
											fontSize: '1.5rem',
											color: theme.palette.text.primary,
										}}
									>
										{page.subtitle}
									</Typography>
									<Divider
										sx={{
											width: '4rem',
											mt: '1rem',
											mb: '0',
											background: theme.palette.primary.dark,
										}}
									></Divider>
									{page.content}
								</CardContent>
							</Card>
						</Grid>
					))}
				</Grid>
			</Container>
		</>
	);
}
