import { Box, Container, Divider, Typography, useTheme } from '@mui/material';
import React from 'react';
import headerPicture from '../assets/pictures/header_impressum.jpg';

export default function Impressum() {
	const theme = useTheme();

	return (
		<>
			<Box
				sx={{
					width: '100%',
					maxHeight: '40vh',
					objectFit: 'cover',
					objectPosition: '50% 30%',
				}}
				component="img"
				position={'relative'}
				src={headerPicture}
				alt="Impressum-Titelbild"
			></Box>

			<Container maxWidth="md" sx={{ mt: 4 }}>
				<Typography variant="h4">Impressum</Typography>
				<Divider
					sx={{
						width: '4rem',
						my: '1rem',
						background: theme.palette.primary.dark,
					}}
				></Divider>
				<Typography>
					<strong>Angaben gemäß § 5 TMG:</strong>
					<br />
					<br />
					Stefan Grauf
					<br />
					Gartenstrasse 49
					<br />
					72764 Reutlingen
					<br />
					Tel: 0 7121 / 364 026-0
					<br />
					Fax: 0 7121 / 364 026-6
					<br />
					Email:{' '}
					<a href="mailto:info@kanzlei-grauf.de">info@kanzlei-grauf.de</a>
					<br />
					<br />
					Umsatzsteuer-Identifikationsnummer gemäß § 27a Umsatzsteuergesetz: DE
					260946961
					<br />
					<br />
					Die Rechtsanwälte sind in der Bundesrepublik Deutschland zugelassen
					und gehören der Rechtsanwaltskammer Tübingen, Christophstraße 30,
					72072 Tübingen an. Die Website der Rechtsanwaltskammer findet sich
					unter{' '}
					<a mat-button href="http://www.rechtsanwaltskammer-tuebingen.de">
						www.rechtsanwaltskammer-tuebingen.de
					</a>
					. Für die anwaltliche Tätigkeit gelten folgende Gebühren- und
					Berufsordnungen: BRAO - Bundesrechtsanwaltsordnung, BORA -
					Berufsordnung für Rechtsanwälte, FAO - Fachanwaltsordnung, RVG -
					Rechtsanwaltsvergütungsgesetz, CCBE - Berufsregeln der Rechtsanwälte
					der Europäischen Gemeinschaft. Sämtliche Texte der berufsrechtlichen
					Regelungen finden sich auf der Website der Bundesrechtsanwaltskammer
					unter{' '}
					<a mat-button href="http://www.brak.de">
						www.brak.de
					</a>
					.
				</Typography>
			</Container>
		</>
	);
}
