import {
	Box,
	Button,
	Card,
	CardContent,
	Container,
	Divider,
	Grid,
	Icon,
	Link,
	Typography,
	useTheme,
} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import HomeIcon from '@mui/icons-material/Home';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import PrintIcon from '@mui/icons-material/Print';
import anfahrtsskizzePicture from '../assets/pictures/anfahrtsskizze.jpg';
import headerPicture from '../assets/pictures/header_kontakt.jpg';

export default function Kontakt() {
	const theme = useTheme();

	return (
		<>
			<Box
				sx={{
					width: '100%',
					maxHeight: '40vh',
					objectFit: 'cover',
					objectPosition: '50% 30%',
				}}
				component="img"
				position={'relative'}
				src={headerPicture}
				alt="Kontakt-Titelbild"
			></Box>

			<Container maxWidth="lg">
				<Grid
					container
					sx={{ my: 2 }}
					spacing={2}
					columnSpacing={{ xs: 2, lg: 4 }}
				>
					<Grid item xs={12} md={6}>
						<Card sx={{ height: '100%' }}>
							<CardContent>
								<Typography variant="h4">Kontakt</Typography>
								<Divider
									sx={{
										width: '4rem',
										my: '1rem',
										background: theme.palette.primary.dark,
									}}
								></Divider>
								<Grid container spacing={2}>
									<Grid
										item
										xs={12}
										sm={4}
										sx={{ display: 'flex', flexDirection: 'row' }}
									>
										<Icon sx={{ mr: '1rem', mt: '-0.1rem' }}>
											<HomeIcon />
										</Icon>
										<Typography>Anschrift:</Typography>
									</Grid>
									<Grid item xs={12} sm={8}>
										Kanzlei Grauf & Dr. Schneider
										<br />
										Rechtsanwaltskanzlei Notarkanzlei
										<br />
										Gartenstraße 49
										<br />
										72764 Reutlingen
									</Grid>
									<Grid
										item
										xs={12}
										sm={4}
										sx={{ display: 'flex', flexDirection: 'row' }}
									>
										<Icon sx={{ mr: '1rem', mt: '-0.1rem' }}>
											<EmailIcon />
										</Icon>
										<Typography>Email:</Typography>
									</Grid>
									<Grid item xs={12} sm={8}>
										<Link
											href="mailto:info@kanzlei-grauf.de"
											sx={{
												color: '#5d5f64',
											}}
										>
											info@kanzlei-grauf.de
										</Link>
									</Grid>
									<Grid
										item
										xs={12}
										sm={4}
										sx={{ display: 'flex', flexDirection: 'row' }}
									>
										<Icon sx={{ mr: '1rem', mt: '-0.1rem' }}>
											<PhoneIcon />
										</Icon>
										<Typography>Telefon:</Typography>
									</Grid>
									<Grid item xs={12} sm={8}>
										<Link
											href="tel:07121-364026-0"
											sx={{
												color: 'inherit',
											}}
										>
											07121 364026-0
										</Link>
									</Grid>
									<Grid
										item
										xs={12}
										sm={4}
										sx={{ display: 'flex', flexDirection: 'row' }}
									>
										<Icon sx={{ mr: '1rem', mt: '-0.1rem' }}>
											<PrintIcon />
										</Icon>
										<Typography>Telefax:</Typography>
									</Grid>
									<Grid item xs={12} sm={8}>
										<Link
											href="tel:07121-364026-6"
											sx={{
												color: '#5d5f64',
											}}
										>
											07121 364026-6
										</Link>
									</Grid>
								</Grid>
							</CardContent>
						</Card>
					</Grid>

					<Grid item xs={12} md={6}>
						<Card sx={{ height: '100%' }}>
							<CardContent>
								<Typography variant="h4">Standort</Typography>
								<Divider
									sx={{
										width: '4rem',
										my: '1rem',
										background: theme.palette.primary.dark,
									}}
								></Divider>

								<Grid container spacing={2} maxWidth="lg">
									<Grid item xs={12} sm={8}>
										Unsere Kanzlei befindet sich im Zentrum Reutlingens direkt
										neben dem Kino &bdquo;Planie&rdquo;. In der näheren Umgebung
										befinden sich öffentliche Parkplätze und Parkhäuser in
										ausreichender Anzahl. Darüber hinaus verfügt die Kanzlei
										über mehrere Parkplätze hinter dem Gebäude, die
										ausschließlich für unsere Mandanten reserviert sind.
										<br /> <br />
										<Button
											variant="outlined"
											href="http://maps.google.de/maps?f=q&source=s_q&hl=de&geocode=&q=gartenstr.+49,+72764+reutlingen&sll=51.151786,10.415039&sspn=20.585961,39.375&ie=UTF8&z=16&iwloc=A"
											target="_blank"
											sx={{
												color: 'inherit',
												'&:hover': {
													color: 'white',
													backgroundColor: theme.palette.primary.dark,
												},
											}}
										>
											Im Routenplaner ansehen.
										</Button>
									</Grid>
									<Grid item xs={12} sm={4}>
										<Box>
											<Tooltip title="Google Maps öffnen.">
												<Button
													href="http://maps.google.de/maps?f=q&source=s_q&hl=de&geocode=&q=gartenstr.+49,+72764+reutlingen&sll=51.151786,10.415039&sspn=20.585961,39.375&ie=UTF8&z=16&iwloc=A"
													target="_blank"
													sx={{ display: 'flex' }}
												>
													<Box
														sx={{ width: '100%' }}
														component="img"
														position={'relative'}
														src={anfahrtsskizzePicture}
													></Box>
												</Button>
											</Tooltip>
											<Typography
												sx={{
													opacity: '0.5',
													fontStyle: 'italic',
													fontSize: '0.8rem',
													textAlign: 'center',
												}}
											>
												Quelle: Google Maps
											</Typography>
										</Box>
									</Grid>
								</Grid>
							</CardContent>
						</Card>
					</Grid>
				</Grid>
			</Container>
		</>
	);
}
