import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import PrintIcon from '@mui/icons-material/Print';
import { Container, Divider, Drawer, Toolbar, useTheme } from '@mui/material';
import Button from '@mui/material/Button/Button';
import Grid from '@mui/material/Grid/Grid';
import Icon from '@mui/material/Icon/Icon';
import { useState } from 'react';
import Datenschutz from './helper/datenschutz';
import { NavLink } from 'react-router-dom';

export default function BottomBar() {
	const theme = useTheme();
	const [datenschutzOpen, setDatenschutzOpen] = useState<boolean>(false);

	return (
		<Toolbar
			sx={{
				backgroundColor: theme.palette.primary.dark,
				color: 'white',
			}}
		>
			<Container maxWidth="lg">
				<Grid container columnSpacing={4} sx={{ p: 3 }}>
					<Grid item xs={12} md={4} sx={{ textAlign: 'center' }}>
						<br />
						Kanzlei Grauf & Dr. Schneider
						<br />
						Gartenstraße 49
						<br />
						72764 Reutlingen
					</Grid>
					<Divider
						sx={{
							width: '100%',
							my: '1rem',
							background: 'white',
							display: { md: 'none' },
						}}
					></Divider>
					<Grid
						item
						xs={12}
						md={4}
						sx={{
							display: 'flex',
							flexDirection: 'column',
						}}
					>
						<Button
							href="mailto:info@kanzlei-grauf.de"
							sx={{
								mb: '0.2rem',
								width: '100%',
								justifyContent: 'center',
								'&:hover': {
									color: 'black',
									backgroundColor: theme.palette.primary.main,
								},
								textTransform: 'none',
							}}
						>
							<Icon sx={{ mr: '1rem', mt: '-8px' }}>
								<EmailIcon />
							</Icon>
							info@kanzlei-grauf.de
						</Button>
						<Button
							href="tel:07121-364026-0"
							sx={{
								mb: '0.2rem',
								width: '100%',
								justifyContent: 'center',
								'&:hover': {
									color: 'black',
									backgroundColor: theme.palette.primary.main,
								},
							}}
						>
							<Icon sx={{ mr: '1rem', mt: '-8px' }}>
								<PhoneIcon />
							</Icon>
							07121 364026-0
						</Button>
						<Button
							href="tel:07121-364026-6"
							sx={{
								width: '100%',
								justifyContent: 'center',

								'&:hover': {
									color: 'black',

									backgroundColor: theme.palette.primary.main,
								},
							}}
						>
							<Icon sx={{ mr: '1rem', mt: '-8px' }}>
								<PrintIcon />
							</Icon>
							07121 364026-6
						</Button>
					</Grid>
					<Divider
						sx={{
							width: '100%',
							my: '1rem',
							background: 'white',
							display: { md: 'none' },
						}}
					></Divider>
					<Grid
						item
						xs={12}
						md={4}
						sx={{
							display: 'flex',
							flexDirection: 'column',
						}}
					>
						<Button
							sx={{
								mt: '1rem',
								width: '100%',
								justifyContent: 'center',
								'&:hover': {
									color: 'black',
									backgroundColor: theme.palette.primary.main,
								},
							}}
							onClick={() => setDatenschutzOpen(true)}
						>
							Datenschutzerklärung
						</Button>
						<Drawer
							anchor="bottom"
							open={datenschutzOpen}
							onClose={() => setDatenschutzOpen(false)}
							PaperProps={{
								sx: {
									width: { sx: '100%', md: '80%' },
									maxHeight: '80%',
									mx: 'auto',
								},
							}}
						>
							<Container maxWidth="sm">
								<Datenschutz setOpen={setDatenschutzOpen} />
							</Container>
						</Drawer>
						<Button
							component={NavLink}
							to={`/impressum`}
							sx={{
								mt: '1rem',
								width: '100%',
								justifyContent: 'center',
								'&:hover': {
									color: 'black',
									backgroundColor: theme.palette.primary.main,
								},
							}}
						>
							Impressum
						</Button>
					</Grid>
				</Grid>
			</Container>
		</Toolbar>
	);
}
