import { Button, useTheme } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';

export default function Datenschutz(props: {
	setOpen: Dispatch<SetStateAction<boolean>>;
}) {
	const theme = useTheme();

	return (
		<>
			<h2>Datenschutzerklärung</h2>
			<strong>§ 1 Information über die Erhebung personenbezogener Daten</strong>
			<br />
			<br />
			(1) Im Folgenden informieren wir über die Erhebung personenbezogener Daten
			bei Nutzung unserer Website. Personenbezogene Daten sind alle Daten, die
			auf Sie persönlich beziehbar sind, z.B. Name, Adresse, E-Mail-Adressen,
			Nutzerverhalten.
			<br />
			<br />
			(2) Verantwortlicher gem. Art. 4 Abs. 7 EU-Datenschutz-Grundverordnung
			(DS-GVO) ist <br />
			<br />
			Stefan Grauf
			<br />
			Gartenstrasse 49
			<br />
			72764 Reutlingen
			<br />
			<br />
			Unseren Datenschutzbeauftragten erreichen Sie unter
			<br />
			<br />
			Thomas Keis
			<br />
			<a href="mailto:t.keis@ram-sued.de">t.keis@ram-sued.de</a>
			<br />
			<br />
			oder unter unserer Postadresse mit dem Zusatz „der
			Datenschutzbeauftragte“.
			<br />
			<br />
			(3) Bei Ihrer Kontaktaufnahme mit uns per E-Mail oder über ein
			Kontaktformular werden die von Ihnen mitgeteilten Daten (Ihre
			E-Mail-Adresse, ggf. Ihr Name und Ihre Telefonnummer) von uns gespeichert,
			um Ihre Fragen zu beantworten. Die in diesem Zusammenhang anfallenden
			Daten löschen wir, nachdem die Speicherung nicht mehr erforderlich ist,
			oder schränken die Verarbeitung ein, falls gesetzliche
			Aufbewahrungspflichten bestehen.
			<br />
			<br />
			(4) Falls wir für einzelne Funktionen unseres Angebots auf beauftragte
			Dienstleister zurückgreifen oder Ihre Daten für werbliche Zwecke nutzen
			möchten, werden wir Sie untenstehend im Detail über die jeweiligen
			Vorgänge informieren. Dabei nennen wir auch die festgelegten Kriterien der
			Speicherdauer.
			<br />
			<br />
			<strong>§ 2 Ihre Rechte</strong>
			<br />
			<br />
			(1) Sie haben gegenüber uns folgende Rechte hinsichtlich der Sie
			betreffenden personenbezogenen Daten:
			<br />
			<br />
			–Recht auf Auskunft,
			<br />
			–Recht auf Berichtigung oder Löschung
			<br />
			–Recht auf Einschränkung der Verarbeitung,
			<br />
			–Recht auf Widerspruch gegen die Verarbeitung,
			<br />
			–Recht auf Datenübertragbarkeit.
			<br />
			<br />
			<br />
			(2) Sie haben zudem das Recht, sich bei einer Datenschutz-Aufsichtsbehörde
			über die Verarbeitung Ihrer personenbezogenen Daten durch uns zu
			beschweren.
			<br />
			<br />
			<strong>
				§ 3 Erhebung personenbezogener Daten bei Besuch unserer Website
			</strong>
			<br />
			<br />
			(1) Bei der bloß informatorischen Nutzung der Website, also wenn Sie sich
			nicht registrieren oder uns anderweitig Informationen übermitteln, erheben
			wir nur die personenbezogenen Daten, die Ihr Browser an unseren Server
			übermittelt. Wenn Sie unsere Website betrachten möchten, erheben wir die
			folgenden Daten, die für uns technisch erforderlich sind, um Ihnen unsere
			Website anzuzeigen und die Stabilität und Sicherheit zu gewährleisten
			(Rechtsgrundlage ist Art. 6 Abs. 1 S. 1 lit. f DS-GVO):
			<br />
			<br />
			–IP-Adresse
			<br />
			–Datum und Uhrzeit der Anfrage
			<br />
			–Zeitzonendifferenz zur Greenwich Mean Time (GMT)
			<br />
			–Inhalt der Anforderung (konkrete Seite)
			<br />
			–Zugriffsstatus/HTTP-Statuscode
			<br />
			–jeweils übertragene Datenmenge
			<br />
			–Website, von der die Anforderung kommt
			<br />
			–Browser
			<br />
			–Betriebssystem und dessen Oberfläche
			<br />
			–Sprache und Version der Browsersoftware.
			<br />
			<br />
			(2) Zusätzlich zu den zuvor genannten Daten werden bei Ihrer Nutzung
			unserer Website Cookies auf Ihrem Rechner gespeichert. Bei Cookies handelt
			es sich um kleine Textdateien, die auf Ihrer Festplatte dem von Ihnen
			verwendeten Browser zugeordnet gespeichert werden und durch welche der
			Stelle, die den Cookie setzt (hier durch uns), bestimmte Informationen
			zufließen. Cookies können keine Programme ausführen oder Viren auf Ihren
			Computer übertragen. Sie dienen dazu, das Internetangebot insgesamt
			nutzerfreundlicher und effektiver zu machen.
			<br />
			<br />
			(3) Einsatz von Cookies:
			<br />
			<br />
			a) Diese Website nutzt folgende Arten von Cookies, deren Umfang und
			Funktionsweise im Folgenden erläutert werden:
			<br />
			–Transiente Cookies (dazu b)
			<br />
			–Persistente Cookies (dazu c)
			<br />
			b) Transiente Cookies werden automatisiert gelöscht, wenn Sie den Browser
			schließen. Dazu zählen insbesondere die Session-Cookies. Diese speichern
			eine sogenannte Session-ID, mit welcher sich verschiedene Anfragen Ihres
			Browsers der gemeinsamen Sitzung zuordnen lassen. Dadurch kann Ihr Rechner
			wiedererkannt werden, wenn Sie auf unsere Website zurückkehren. Die
			Session-Cookies werden gelöscht, wenn Sie sich ausloggen oder den Browser
			schließen.
			<br />
			<br />
			c) Persistente Cookies werden automatisiert nach einer vorgegebenen Dauer
			gelöscht, die sich je nach Cookie unterscheiden kann. Sie können die
			Cookies in den Sicherheitseinstellungen Ihres Browsers jederzeit löschen.
			<br />
			<br />
			d) Sie können Ihre Browser-Einstellung entsprechend Ihren Wünschen
			konfigurieren und z.B. die Annahme von Third-Party-Cookies oder allen
			Cookies ablehnen. Wir weisen Sie darauf hin, dass Sie eventuell nicht alle
			Funktionen dieser Website nutzen können.
			<br />
			<br />
			e) Wir setzen Cookies ein, um Sie für Folgebesuche identifizieren zu
			können, falls Sie über einen Account bei uns verfügen. Andernfalls müssten
			Sie sich für jeden Besuch erneut einloggen.
			<br />
			<br />
			<br />
			<Button
				variant="outlined"
				onClick={() => props.setOpen(false)}
				sx={{
					width: '100%',
					color: 'inherit',
					'&:hover': {
						color: 'white',
						backgroundColor: theme.palette.primary.dark,
					},
				}}
			>
				Schließen
			</Button>
		</>
	);
}
