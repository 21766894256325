import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Card,
	CardContent,
	Divider,
	Typography,
	useTheme,
} from '@mui/material';
import { Container } from '@mui/system';
import { useState } from 'react';
import headerPicture from '../assets/pictures/header_rechtsgebiete.jpg';
import { SubPages } from '../content';

export const pages: SubPages = [
	{
		title: 'Anwaltskanzlei',

		content: (
			<>
				Die Anwaltskanzlei Grauf und Kollegen ist spezialisiert im Familien- und
				Erbrecht tätig. Diese konsequente Schwerpunktsetzung, langjährige
				Erfahrung der tätigen Anwälte und regelmäßige, fachspezifische
				Fortbildung ist die Basis für eine fundierte Rechtsberatung mit dem
				Ziel, Ihre Interessen im außergerichtlichen und gerichtlichen Bereich
				kompetent zu vertreten und durchzusetzen.
				<br /> <br />
				Die vielfältigen und komplexen Zusammenhänge zwischen Familien-,
				Vermögens-, Steuer- und Erbrecht und der notariellen Praxis der
				Vertragsgestaltung, beispielsweise bei der Fertigung von Ehe- und
				Erbverträgen, Scheidungsvereinbarungen und Testamenten, erfordert ein
				hohes Maß an fachlicher Kompetenz, die nur durch eine entsprechende
				Spezialisierung erlangt und stets auf dem aktuellen Stand erhalten
				werden kann.
				<br /> <br />
				Unsere Anwälte betreuen Sie persönlich und individuell vom ersten
				Beratungsgespräch in unserer Kanzlei bis hin zur Vertretung vor Gericht
				und Durchsetzung Ihrer Ansprüche im Zwangsvollstreckungsverfahren.
			</>
		),
	},
	{
		title: 'Familienrecht',
		content: (
			<>
				Unter dem Begriff Familienrecht findet sich eine Vielzahl von einzelnen
				Rechtsgebieten, die sich wechselseitig beeinflussen können und die in
				vielfältigen rechtlichen Verbindungen zueinander stehen. Nur
				beispielhaft genannt seien folgende Themenbereiche: <br /> <br />
				<ul>
					<br />
					<li>
						Eheschließung <br />
					</li>
					<li>
						Ehescheidung
						<br />
					</li>
					<li>
						Zugewinnausgleich und Vermögensauseinandersetzung
						<br />
					</li>
					<li>
						Regelung in Bezug auf gemeinsame Kinder
						<br />
					</li>
					<li>
						Ehegattenunterhalt
						<br />
					</li>
					<li>
						Kindesunterhalt
						<br />
					</li>
					<li>
						Elternunterhalt
						<br />
					</li>
					<li>
						Verwandtenunterhalt
						<br />
					</li>
				</ul>
				<br /> <br /> Wir beraten Sie kompetent bezüglich sämtlicher Fragen, die
				insbesondere im Rahmen der Eheschließung beziehungsweise anlässlich der
				Trennung und Scheidung auftreten. Entscheidend ist hierbei nicht nur
				spezialisiertes, juristisches Fachwissen, sondern darüber hinaus
				Verständnis für wirtschaftlich sinnvolle Lösungen, gegebenenfalls auch
				unter Berücksichtigung der Interessen gemeinsamer Kinder.
				<br />
			</>
		),
	},
	{
		title: 'Eheschließung',
		content: (
			<>
				Oft ist es angeraten, die Folgen einer möglichen Trennung bereits vor
				der Eheschließung oder noch während ihr vertraglich zu regeln. Ist die
				Trennung erst einmal vollzogen, wird es für die Ehegatten ungleich
				schwieriger, ihre dann oftmals widerstreitenden Interessen im Rahmen
				einer harmonischen Einigung in ein ausgeglichenes Verhältnis zu setzen.
				<br />
				<br />
				Wir beraten die Ehegatten über die gesetzlichen Folgen einer
				Ehescheidung und die vertraglichen Möglichkeiten, diese Folgen durch
				Vereinbarung abzuändern, zu ergänzen und so den individuellen
				Bedürfnissen und den Vorgaben der Rechtsprechung anzupassen.
			</>
		),
	},
	{
		title: 'Trennung und Ehescheidung',
		content: (
			<>
				Bereits anlässlich der Trennung entstehen vielfältige Fragen in Bezug
				auf die rechtliche Situation und die Möglichkeit vertraglicher
				Gestaltungen für die Zeit während der Trennung. Haben sich die Eheleute
				entschlossen, die Scheidung durchzuführen, benötigen sie spätestens dann
				anwaltliche Vertretung, da die vor Gericht zu beantragende Ehescheidung
				dem Anwaltszwang unterliegt.
				<br />
				<br />
				Sowohl in der Trennungsphase als auch in der nachehelichen Zeit
				empfiehlt sich - nicht zuletzt als Basis des weiteren Umgangs
				miteinander - eine verbindliche Regelung nachfolgender Sachverhalte:
				<br />
				<br />
				<ul>
					<br />
					<li>
						Kindes- und Ehegattenunterhalt
						<br />
					</li>
					<li>
						Zugewinnausgleich und Vermögensauseinandersetzung
						<br />
					</li>
					<li>
						Versorgungsausgleich (Rentenausgleich)
						<br />
					</li>
					<li>
						Hausratsauseinandersetzung
						<br />
					</li>
					<li>
						Nutzung der gemeinsamen Ehewohnung/des gemeinsamen Hauses
						<br />
					</li>
					<li>
						Sorgerecht (Vermögens- und Personensorge, Aufenthaltsbestimmung) und
						Umgangsregelungen für die gemeinsamen Kinder
						<br />
					</li>
				</ul>
				<br />
				<br />
				Oftmals spielen bei der Regelung der o. g. Punkte auch erb-, steuer -
				und gesellschaftsrechtliche Gesichtspunkte eine erhebliche Rolle.
				Nachdem sich die einzelnen Bereiche wechselseitig - nicht selten
				unbeabsichtigt - beeinflussen, erfordert die Regelung jedes einzelnen
				Themenbereichs umfangreiche und spezialisierte Fachkenntnisse, verbunden
				mit einem "Blick auf das Ganze". <br />
			</>
		),
	},
	{
		title: 'Erbrecht',
		content: (
			<>
				Sowohl im Fall der vorsorgenden Gestaltung letztwilliger Verfügungen als
				auch bei Eintritt eines Erbfalles sind wir Ihr erfahrener
				Ansprechpartner.
				<br />
				<br />
				Wir bieten Ihnen Beratung und Vertretung insbesondere in folgenden
				Bereichen:
				<br />
				<br />
				<ul>
					<br />
					<li>
						Ausgestaltung von Testamenten und Erbverträgen
						<br />
					</li>
					<li>
						Durchsetzung von Rechten aus Testamenten, Erbverträgen und
						gesetzlicher Erbfolge
						<br />
					</li>
					<li>
						Erbschaftsteuerliche Beratung <br />
					</li>
					<li>
						Auseinandersetzungen von Erbengemeinschaften
						<br />
					</li>
					<li>
						Vermächtnis- und Pflichtteilsfragen
						<br />
					</li>
				</ul>
				<br />
			</>
		),
	},
	{
		title: 'Gebühren',
		content: (
			<>
				Die Gebühren und Auslagen, die ein Rechtsanwalt in Rechnung stellen
				darf, sind durch das Rechtsanwaltsvergütungsgesetz (RVG) geregelt. Die
				Höhe der Gebühren richtet sich nach dem sog. Gegenstands- bzw.
				Geschäftswert, also nach dem Wert des Gegenstands, auf den sich die
				anwaltliche Tätigkeit richtet.
				<br /> <br />
				In Einzelfällen der anwaltlichen Tätigkeit können Gebührenvereinbarungen
				getroffen werden, mit denen von den gesetzlichen Gebühren abgewichen
				werden kann.
				<br /> <br />
				Die Kosten einer anwaltlichen Erstberatung betragen - ausnahmsweise
				unabhängig vom Gegenstandswert - höchstens 190,00 € zuzüglich möglicher
				Auslagenpauschale in Höhe von 20,00 € und gesetzlicher Mehrwertsteuer.
				<br /> <br />
				Je nach Art und Umfang des durch Sie abgeschlossenen
				Rechtsschutzversicherungsvertrages können die Kosten anwaltlicher
				Tätigkeit von Ihrer Rechtsschutzversicherung übernommen werden. Eine
				entsprechende Deckungsanfrage gegenüber Ihrer Rechtsschutzversicherung
				wird von uns übernommen.
			</>
		),
	},
];
export default function Notarkanzlei() {
	const theme = useTheme();

	const [expanded, setExpanded] = useState<boolean[]>([
		false,
		false,
		false,
		false,
		false,
	]);

	function toggleAccordionAtIndex(index: number) {
		setExpanded(
			expanded.map((value, localIndex) =>
				localIndex === index ? !value : value
			)
		);
	}

	return (
		<>
			<Box
				sx={{
					width: '100%',
					maxHeight: '40vh',
					objectFit: 'cover',
					objectPosition: '50% 30%',
				}}
				component="img"
				position={'relative'}
				src={headerPicture}
				alt="Anwaltskanzlei-Titelbild"
			></Box>

			<Box
				sx={{
					display: 'flex',
					mt: '-2rem',
					width: '100%',
					py: '1rem',
					pb: '2rem',
					backgroundColor: '#F4F6F9',
					justifyContent: 'center',
				}}
			>
				<Container maxWidth="md" sx={{ pt: 4 }}>
					<Card>
						<CardContent>
							<Typography variant="h4">{pages[0].title}</Typography>
							<Divider
								sx={{
									width: '4rem',
									my: '1rem',
									background: theme.palette.primary.light,
								}}
							></Divider>
							<Box sx={{ p: 1 }}>{pages[0].content}</Box>
						</CardContent>
					</Card>
				</Container>
			</Box>

			<Container maxWidth="md" sx={{ p: { xs: 2, sm: 4 } }}>
				<Typography variant="h4">Rechtsgebiete</Typography>
				<br />
				{pages.slice(1).map((subPage, index) => (
					<Accordion
						key={subPage.title}
						expanded={expanded[index]}
						onChange={() => toggleAccordionAtIndex(index)}
					>
						<AccordionSummary expandIcon={<ExpandMoreIcon />}>
							<Typography variant="h4">{subPage.title}</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<Divider
								sx={{
									width: '4rem',
									my: '1rem',
									background: theme.palette.primary.dark,
								}}
							></Divider>

							<Box sx={{ p: 1 }}>{subPage.content}</Box>
						</AccordionDetails>
					</Accordion>
				))}
			</Container>
		</>
	);
}
