import {
	Box,
	createTheme,
	CssBaseline,
	ThemeProvider,
	Toolbar,
} from '@mui/material';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter as Router } from 'react-router-dom';
import BottomBar from './components/bottombar';
import NavBar from './components/navbar';
import MyRoutes from './components/routes';

const theme = createTheme({
	palette: {
		primary: {
			light: '#eaedf4',
			main: '#d9e2eb',
			dark: '#7f95b6',
		},
		text: {
			primary: '#5d5f64',
		},
	},
	typography: {
		fontFamily: ['Raleway', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(
			','
		),
		h4: {
			fontFamily: 'Raleway, sans-serif',
			fontWeight: '200',
			lineHeight: '1.2',
			color: '#212529',
			fontSize: '1.875rem',
		},
		h6: {
			fontFamily: '"Cormorant Garamond", sans-serif',
		},
	},
});

function App() {
	return (
		<HelmetProvider>
			<ThemeProvider theme={theme}>
				<Router>
					<Box
						sx={{
							minHeight: '100%',
							display: 'flex',
							justifyContent: 'space-between',
							flexDirection: 'column',
						}}
					>
						<Box sx={{ display: 'flex', flexDirection: 'column' }}>
							<CssBaseline />
							<NavBar />
							<Box component="main" width={'100%'}>
								<Toolbar />
								<MyRoutes />
							</Box>
						</Box>
						<BottomBar />
					</Box>
				</Router>
			</ThemeProvider>
		</HelmetProvider>
	);
}

export default App;
